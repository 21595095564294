import React from 'react';
// import Sprite from '../../sprites/sprite';
import { VideoCardItem } from '../../../constants/video-cards';

const colors = {
  blue: 'bg-blue-100',
  yellow: 'bg-yellow-100',
  red: 'bg-red-100',
  green: 'bg-green-100',
} as const;

export const VideoCard: React.FC<VideoCardItem> = ({ description, title, color }) => {
  return (
    <div
      className={`${colors[color]} font-serif flex flex-col p-6 md:px-8 md:py-8 w-full rounded-xl`}
    >
      <span className="flex flex-row items-center gap-3 md:gap-4">
        <p className="font-bold text-xl md:text-2xl">{title}</p>{' '}
        {/* <Sprite name="play" width="32" height="32" className="w-6 h-6 md:w-8 md:h-8" /> */}
      </span>
      <p className="mt-2 md:mt-3 max-w-[360px]">{description}</p>
    </div>
  );
};
