import { images } from './images';

interface CarouselItem {
  imageSrc: string;
  imageSrcAlt: string;
  quote: string;
  doha: string;
}

export const carousel: CarouselItem[] = [
  {
    imageSrc: images['carousel-1'],
    imageSrcAlt:
      'Anant Shri Sadguru Sadafal Deo Ji Maharaj seated under a large tree, with His Holy Scripture the Swarved.',
    quote:
      "“The status of 'Yogī' is only bestowed upon one has become free from life and death. It is the passing of such an individual which is lauded.”",
    doha: 'M3/C1/D3',
  },
  {
    imageSrc: images['chariot'],
    imageSrcAlt:
      'A set of carved statues that represent a horse, reins, a chariot and charioteer with a master also seated in the chariot.',
    quote:
      'The faculties of action & perception are like a horse, the Mind a rein, intellect a charioteer, and body a chariot. The Soul is their master.',
    doha: 'M5/C10/09',
  },
];
