import { useCallback, useState } from 'react';
import clsx from 'clsx';
import Sprite from '../sprites/sprite';
import { amounts } from '../sections/footer';
import { useFetcher } from '@remix-run/react';

export const Donate = () => {
  const [amount, setDonation] = useState('');
  // const [error, setError] = useState('');
  const onClear = useCallback(() => setDonation(''), []);

  const fetcher = useFetcher({ key: 'vy-uk-donation' });

  const onSubmit = useCallback(() => {
    if (isNaN(parseInt(amount)) || !parseInt(amount)) {
      // setError('Please provide a valid amount.');
    } else {
      fetcher.submit(
        { intent: 'donation', amount },
        { method: 'post', action: '/?root' },
      );
    }
  }, [amount, fetcher]);

  return (
    <div
      id="donate-section"
      className="px-12 py-6 gap-2 bg-white w-full max-w-[1008px] rounded-b-xl flex flex-col sm:flex-row items-end"
    >
      <div className="w-full sm:w-1/2">
        <div className="flex flex-row flex-wrap items-center">
          <p className="font-display font-bold text-xl mr-2">Donate now</p>
          <img
            src="/gc.png"
            alt="Powered by gocardless"
            className="h-8 object-contain pointer-events-none select-none"
          />
        </div>
        <p className="mt-4">
          We are humbled by your support. All funds will be used for organisation of
          events and propagation of Vihangam Yog.
        </p>
        <button
          disabled={!amount}
          className={clsx(
            'hidden sm:flex px-5 py-3 mt-5 uppercase tracking-widest text-xs bg-yellow-100 rounded-md cursor-auto',
            {
              'hover:bg-yellow-200 active:bg-yellow-50 cursor-pointer opacity-100':
                !!amount,
              'opacity-50': !amount,
            },
          )}
          onClick={onSubmit}
        >
          Donate
        </button>
      </div>
      <div className="w-full mt-6 sm:mt-0 sm:w-1/2 flex flex-col">
        <div className="bg-gray-100 p-4 pl-7 flex flex-row items-center rounded-md relative">
          <input
            value={amount}
            type="number"
            onChange={(e) => setDonation(e.target.value)}
            className="w-full bg-gray-100 p-0 outline-none"
            placeholder="Enter your donation amount"
          />
          {amount ? (
            <button
              onClick={onClear}
              className="absolute right-12 top-1/2 -translate-y-1/2"
            >
              <Sprite name="close" width={18} height={18} className="h-6" />
            </button>
          ) : null}
          <span className="absolute left-3.5 top-1/2 -translate-y-1/2">£</span>
        </div>
        <div className="flex flex-row items-center flex-wrap justify-center sm:justify-start">
          {amounts.map((amount, key) => (
            <button
              key={key}
              className={clsx(
                'hover:bg-blue-500 transition hover:text-white px-4 py-2 mt-5 mr-2 sm:mr-5 last-of-type:mr-0 uppercase tracking-wider text-sm rounded-md text-blue-500 border-2 border-blue-500',
                {
                  'bg-blue-500 text-white': amount === amount,
                  'bg-white': amount !== amount,
                },
              )}
              onClick={() => setDonation(amount)}
            >
              £{amount}
            </button>
          ))}
        </div>
        <button
          disabled={!amount}
          className={clsx(
            'sm:hidden px-5 py-3 mt-5 uppercase tracking-widest text-xs bg-yellow-100 rounded-md cursor-auto',
            {
              'hover:bg-yellow-200 active:bg-yellow-50 cursor-pointer opacity-100':
                !!amount,
              'opacity-50': !amount,
            },
          )}
          onClick={onSubmit}
        >
          Donate
        </button>
      </div>
    </div>
  );
};
