import React, { useCallback, useEffect, useState } from 'react';
import { Footer } from '../components/footer/footer';
import { GetInTouch, Seeker } from '../components/get-in-touch/get-in-touch';
import { useFetcher } from '@remix-run/react';
import clsx from 'clsx';
import { Donate } from '../components/donate';

interface FooterSectionProps {
  showInquiry?: boolean;
}

export const amounts = ['10', '50', '100', '200'];

export const FooterSection: React.FC<FooterSectionProps> = ({ showInquiry = true }) => {
  const fetcher = useFetcher();
  const [showSuccess, setShowSuccess] = useState(false);
  const onSubmit = useCallback(
    (seeker: Seeker) => {
      fetcher.submit(
        {
          ...seeker,
          interests: JSON.stringify(seeker.interests),
        },
        { method: 'post', action: '/?index' },
      );
    },
    [fetcher],
  );

  useEffect(() => {
    if (fetcher.data === 'OK') {
      setShowSuccess(true);
    }
  }, [fetcher.data]);

  return (
    <section id="get-in-touch-section" className="w-full m-auto">
      {showInquiry && (
        <div
          className={clsx(
            'z-10 relative m-auto flex flex-col items-center justify-center',
            {
              '-mb-20': showSuccess,
              '-mb-96': !showSuccess,
            },
          )}
        >
          <GetInTouch onSubmit={onSubmit} showSuccess={showSuccess} />
          <Donate />
        </div>
      )}
      <Footer topSteps={showInquiry ? (showSuccess ? 'pt-40' : 'pt-100') : undefined} />
    </section>
  );
};
