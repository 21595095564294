import React from 'react';
import { json, type ActionFunctionArgs, type MetaFunction } from '@remix-run/cloudflare';

import type { LinksFunction } from '@remix-run/node';
import stylesheet from '../tailwind.css?url';

import { Carousel } from '../components/carousel/carousel';
import { TestimonialSection } from '../sections/testimonials';
import { BenefitsSection } from '../sections/benefits';
import { MastersSection } from '../sections/Masters';
import { SarkarSection } from '../sections/Sarkar';
import { FAQSection } from '../sections/faq';
import { FooterSection } from '../sections/footer';
import { getPrefersReducedMotion } from '../accessibility/motion';
import { VYUKHeader } from '../components/header/vy-uk-header';
import { Seeker } from '../components/get-in-touch/get-in-touch';
import { havanDialog } from '../state/havan-dialog';
import { isRetryableNetworkError, retry } from '../network/retry';
import { useLocation } from '@remix-run/react';
import clsx from 'clsx';

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: stylesheet }];

export const meta: MetaFunction = () => {
  return [
    { title: 'Vihangam Yog UK' },
    {
      name: 'description',
      content: 'The United Kingdom institute of Vihangam Yog',
    },
  ];
};

const SCROLL_OFFSET = 60;

export const goToSelector = (selector: string) => {
  const element = document.getElementById(selector);
  if (!element) return;
  const prefersReducedMotion = getPrefersReducedMotion();

  window.scrollTo({
    behavior: prefersReducedMotion ? 'instant' : 'smooth',
    top: element.getBoundingClientRect().top - SCROLL_OFFSET,
  });
};

export const nav = [
  {
    title: 'Benefits',
    link: 'benefits-section',
  },
  {
    title: 'The Masters',
    link: 'Masters-section',
  },
  {
    title: 'Faq',
    link: 'faq-section',
  },
];

export const action = async ({ request }: ActionFunctionArgs) => {
  try {
    const data = await request.formData();

    const name = data.get('name')?.toString();
    const interests = data.get('interests')?.toString();
    const email = data.get('email')?.toString();

    if (!name || !interests || !email) {
      return json('Not found', 404);
    }

    const interestsArr = JSON.parse(interests) as string[];

    const inquiryObj = {
      name,
      interests: interestsArr,
      email,
    } satisfies Seeker;

    try {
      const response = await retry(
        'post-inquiry',
        () =>
          fetch('https://api.vihangamyog.uk/api/inquiry', {
            method: 'POST',
            body: JSON.stringify(inquiryObj),
            headers: {
              'Content-Type': 'application/json',
            },
          }),
        {
          shouldRetryOnSuccess: isRetryableNetworkError,
        },
      );

      if (response.ok) {
        return json('OK', 200);
      }

      return Error('Bad request: ' + (await response.text()));
    } catch (e) {
      console.error(e);
      return json('Bad request', 500);
    }
  } catch (e) {
    return json('Not found', 404);
  }
};

export default function Index() {
  return (
    <div className="w-full h-full relative">
      <VYUKHeader />
      <main className="xs:mt-12 mb-16 sm:mt-24 w-full">
        <Carousel />
        <p className="text-2xl px-3 sm:text-4xl text-center max-w-[95%] font-display font-black italic m-auto mt-8 xs:mt-15">
          The most powerful, ancient meditation
          <br />
          technique, rediscovered for the modern world.
        </p>
        <HavanCTA />
        <TestimonialSection />
        <BenefitsSection />
        <MastersSection />
        <SarkarSection />
        {/* <HavanCTA /> */}
        <FAQSection />
      </main>
      <FooterSection />
    </div>
  );
}

interface BookNowProps {
  location: 'Leeds' | 'Leicester';
  eventUrl: string;
}

const BookNowButtonDesktop: React.FC<BookNowProps> = ({ location, eventUrl }) => {
  return (
    <div className="pl-5 flex w-full sm:w-[unset] gap-2 justify-center flex-row sm:justify-normal items-center sm:tracking-widest text-sm sm:text-xs bg-yellow-100 rounded-md">
      <button
        onClick={() => {
          havanDialog.isOpen = true;
          havanDialog.location = location;
          havanDialog.eventUrl = eventUrl;
        }}
        className="sm:uppercase"
      >
        Book now
      </button>
      <button
        onClick={() => {
          havanDialog.isOpen = true;
          havanDialog.location = location;
          havanDialog.eventUrl = eventUrl;
        }}
      >
        <img className="h-10 hover:scale-110 transition" alt="Gc logo" src="/gc.png" />
      </button>
    </div>
  );
};

const events = [
  {
    city: 'Leicester',
    name: 'Maher Centre',
    date: '09 August 2024',
    time: '3:30pm - 8:30pm',
    hasBooking: true,
    source:
      'https://pub-24db26cc83d24526bdd25020f72d9ed8.r2.dev/VY%20Flyer%20-%20Leicester%20Aug%202024.pdf',
    id: 'leicester',
    tags: ['discourses', 'meditation', 'yajna'],
    eventUrl: 'https://www.facebook.com/events/346225531871289',
  },
  {
    city: 'London',
    name: 'Tooting Broadway',
    date: '10 August 2024',
    time: '5:30pm - 8:30pm',
    hasBooking: false,
    source:
      'https://pub-24db26cc83d24526bdd25020f72d9ed8.r2.dev/Combined%20Flyer%20-%20UK%20Aug%202024.pdf',
    id: 'broadway',
    tags: ['discourses', 'meditation'],
    eventUrl: '',
  },
  {
    city: 'London',
    name: 'Bromley',
    date: '11 August 2024',
    time: '5:00pm - 8:00pm',
    hasBooking: false,
    source: 'https://pub-24db26cc83d24526bdd25020f72d9ed8.r2.dev/VY%20UK%20Bromley.pdf',
    id: 'bromley',
    tags: ['discourses', 'meditation'],
    eventUrl: '',
  },
  {
    city: 'London',
    name: 'Slough',
    date: '15 August 2024',
    time: '4:00pm - 8:00pm',
    hasBooking: false,
    source:
      'https://pub-24db26cc83d24526bdd25020f72d9ed8.r2.dev/Combined%20Flyer%20-%20UK%20Aug%202024.pdf',
    id: 'slough',
    tags: ['discourses', 'meditation'],
    eventUrl: '',
  },
  {
    city: 'Leeds',
    name: 'Leeds Temple',
    date: '17 August 2024',
    time: '3:00pm - 8:00pm',
    hasBooking: true,
    source:
      'https://pub-24db26cc83d24526bdd25020f72d9ed8.r2.dev/VY%20UK%20Hawan%20Leeds.pdf',
    id: 'leeds',
    tags: ['discourses', 'meditation', 'yajna'],
    eventUrl: '',
  },
  {
    city: 'Bradford',
    name: undefined,
    date: '18 August 2024',
    time: '3:00pm - 8:00pm',
    hasBooking: false,
    source: 'https://pub-24db26cc83d24526bdd25020f72d9ed8.r2.dev/VY%20UK%20Bradford.pdf',
    id: 'bradford',
    tags: ['discourses', 'meditation', 'yajna'],
    eventUrl: '',
  },
] as const;

export const HavanCTA = () => {
  const location = useLocation();
  const currentEventSelection = location.hash ? location.hash.replace('#', '') : '';

  return (
    <section
      id="havan-section"
      className="w-full mt-16 py-8 bg-blue-100 items-center md:items-start flex flex-col text-center px-6 md:px-36 lg:px-60"
    >
      <h2 className="text-center sm:text-left font-display font-bold text-xl">
        Upcoming Events in August 2024
      </h2>
      <p className="mt-1 text-center sm:text-left">
        Book now to reserve a spot - suitable for individuals, couples, families.
      </p>
      <div className="grid grid-cols-1 w-full sm:grid-cols-2 items-start gap-4 gap-y-2 whitespace-nowrap mt-2">
        {events.map((e) => (
          <div
            key={e.id}
            id={e.id}
            className={clsx(
              'mt-3 bg-white w-full rounded-md p-4 flex flex-col relative items-center sm:items-start gap-3 flex-wrap justify-center xs:justify-normal',
              {
                'border-[3px] border-blue-400': currentEventSelection === e.id,
                'border-[3px] border-transparent': currentEventSelection !== e.id,
              },
            )}
          >
            {/* <span className="tracking-wider text-[10px] font-serif font-bold absolute top-4 right-4 bg-gray-100 px-2 py-1 rounded-sm">
              {!e.hasBooking ? 'FREE ENTRY' : 'BOOKING ONLY'}
            </span> */}
            <div className="flex flex-col items-center sm:items-start">
              <p className="text-lg font-bold leading-snug">
                {[e.name, e.city].filter((s) => s).join(', ')}
              </p>
              <p className="text-gray-500">
                {e.time} • {e.date}
              </p>
              <div className="flex flex-row flex-wrap justify-center sm:justify-normal items-center gap-2 mt-2">
                {e.tags.map((t) => (
                  <span
                    key={t}
                    className="bg-gray-100 uppercase text-[10px] px-2 py-1 rounded-sm tracking-wider"
                  >
                    {t}
                  </span>
                ))}
              </div>
            </div>
            <div className="flex flex-row items-start gap-3 flex-wrap w-full">
              {e.hasBooking ? (
                <BookNowButtonDesktop location={e.city} eventUrl={e.eventUrl} />
              ) : null}
              <a
                href={e.source}
                target="_blank"
                rel="noreferrer"
                download
                className="w-full sm:w-[unset]"
              >
                <button className="w-full text-center sm:max-w-[unset] px-3 sm:px-5 py-3 sm:uppercase sm:tracking-widest text-sm sm:text-xs bg-blue-100 hover:bg-blue-100 active:bg-blue-50 rounded-md">
                  Download Brochure
                </button>
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
