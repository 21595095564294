import React from 'react';
import Sprite from '../../sprites/sprite';
import { Link } from '@remix-run/react';

export const FACEBOOK = 'https://www.facebook.com/profile.php?id=61562870618296';
export const INSTAGRAM = 'https://www.instagram.com/vihangamyoguk/';
export const TWITTER = 'https://x.com/ukvihangamyog';

interface FooterProps {
  /**
   * Tailwind class controlling top padding
   */
  topSteps?: string;
}

export const Footer: React.FC<FooterProps> = ({ topSteps }) => {
  return (
    <footer id="footer" className="w-full px-6 md:px-36 lg:px-60 bg-yellow-100">
      {topSteps ? <div className={topSteps}></div> : null}
      <section className="max-w-[1008px] mx-auto pt-8">
        <div className="flex flex-col xs:justify-between xs:flex-row">
          <p className="font-display font-bold">
            info@vihangamyog.uk <br /> +44 7405 352695&nbsp; / +44 7459 626234
          </p>
          <ul className="flex flex-row list-none items-center gap-3 pt-2 xs:pt-0">
            <li>
              <a href={FACEBOOK} target="_blank" rel="noreferrer">
                <Sprite name="facebook" width={64} height={64} className="w-8 h-8" />
              </a>
            </li>
            <li>
              <a href={INSTAGRAM} target="_blank" rel="noreferrer">
                <img src="/instagram.png" alt="Instagram" className="w-8 h-8" />
              </a>
            </li>
            <li>
              <a href={TWITTER} target="_blank" rel="noreferrer">
                <Sprite name="twitter" width={64} height={64} className="w-8 h-8" />
              </a>
            </li>
          </ul>
        </div>
        <div className="flex flex-col sm:flex-row gap-4 sm:gap-0 justify-between font-serif py-12">
          <small className="text-sm">
            © All rights reserved, 2024. Vihangam Yog UK / Company: 15233679
          </small>
          <small className="text-sm">
            <Link prefetch="intent" className="hover:underline" to="privacy-policy">
              Privacy Policy
            </Link>
          </small>
        </div>
      </section>
    </footer>
  );
};
