import React, { useRef, useState } from 'react';
import { FAQItem, faqs } from '../../constants/faq';
import Sprite from '../sprites/sprite';
import clsx from 'clsx';

export const FAQSection = () => {
  return (
    <section
      id="faq-section"
      className="mt-8 xs:mt-20 w-full m-auto px-6 md:px-36 lg:px-60"
    >
      <h2 className="m-auto text-center font-display font-black text-3xl">FAQ</h2>
      <div className="mt-6 xs:mt-12 items-start justify-center w-full grid grid-cols-1 sm:grid-cols-2 m-auto gap-4">
        {faqs.map((faq, index) => (
          <FAQ {...faq} key={index} />
        ))}
      </div>
    </section>
  );
};

const FAQ: React.FC<FAQItem> = ({ question, answer }) => {
  const answerRef = useRef<HTMLParagraphElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <details
      open={isOpen}
      onClick={(e) => {
        e.preventDefault();
        if (!(e.target as HTMLElement).contains(answerRef.current)) {
          setIsOpen((prev) => !prev);
        }
      }}
      className="bg-gray-100 py-6 px-5 sm:py-8 sm:px-7 rounded-xl font-serif cursor-pointer select-none"
    >
      <summary className="flex flex-row items-center justify-between font-semibold">
        <span>{question}</span>
        <Sprite
          name="down"
          width="20"
          height="13"
          className={clsx('w-4 h-2 md:w-5 md:h-3 transition', {
            'rotate-180': isOpen,
          })}
        />
      </summary>
      <p ref={answerRef} className="mt-3">
        {answer}
      </p>
    </details>
  );
};
