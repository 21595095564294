import { TestimonialItem } from '../../../constants/testimonials';
import React from 'react';

export const Testimonial: React.FC<TestimonialItem> = ({
  author,
  imageAlt,
  imageSrc,
  quote,
}) => {
  return (
    <div className="flex flex-row items-start gap-3 w-full">
      <img
        loading="lazy"
        src={imageSrc}
        alt={imageAlt}
        className="w-16 h-16 min-w-16 min-h-16 rounded-full object-cover object-[50%_30%] select-none pointer-events-none"
      />
      <div className="flex flex-col font-serif text-base md:text-lg w-full">
        <p className="italic max-w-[640px]">&ldquo;{quote}&rdquo;</p>
        <p className="uppercase mt-4 tracking-wider text-xs text-gray-500">
          {author.name}, {author.location}
        </p>
      </div>
    </div>
  );
};
