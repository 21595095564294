import { images } from './images';

export interface TestimonialItem {
  imageSrc: string;
  imageAlt: string;
  quote: string;
  author: {
    name: string;
    location: string;
  };
}

export const testimonials: TestimonialItem[] = [
  {
    imageSrc: images['image_sarika_ji'],
    imageAlt: '',
    quote:
      'Vihangam Yog has transformed my life. The deep breathing exercises, meditation, and spiritual guidance offer inner peace of a different kind.',
    author: {
      name: 'Sarika Handa',
      location: 'London',
    },
  },
  {
    imageSrc: images['image_vikas_ji'],
    imageAlt: '',
    quote:
      'After meditating consistently, difficult decisions and remembering the smallest of details have become easy. I am a different person.',
    author: {
      name: 'Vikas Shrivastava',
      location: 'Kent',
    },
  },
  {
    imageSrc: images['image_garima_ji'],
    imageAlt: '',
    quote:
      'Vihangam Yog is the epitome of spirituality. Through meditation practice and reading Swarved, I have become a resilient individual.',
    author: {
      name: 'Garima Mehta',
      location: 'England',
    },
  },
  {
    imageSrc: images['image_carmel_ji'],
    imageAlt: '',
    quote:
      'I am having a wonderful journey and long may it continue. Superb experience - everything is worth a try….',
    author: {
      name: 'Carmel Curran',
      location: 'England',
    },
  },
];
