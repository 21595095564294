export interface FAQItem {
  question: string;
  answer: React.ReactNode;
}

export const faqs: FAQItem[] = [
  {
    question: 'What is Vihangam Yog?',
    answer: (
      <>
        Vihangam Yog is an ancient, meditative path and science.
        <br />
        <br />
        It goes by many names in the Ancient Indian scriptures, including{' '}
        <i>Brahma Vidyā</i>. In Vihangam Yog, seekers are instructed into a technique of
        meditation which is utmost powerful and simple, by the Master of the technique,
        the Sadguru. It is the complete path of spirituality, which goes beyond
        self-realization, to the highest peak of the spiritual path.
      </>
    ),
  },
  {
    question: 'What is the Mind?',
    answer: (
      <>
        If you close your eyes and try to control your thoughts, you will find that you
        succeed for a few moments. But, eventually, you lose control and thoughts begin to
        take over you.
        <br />
        <br />
        This &lsquo;friction&rsquo; which we occasionally become aware of: It demonstrates
        the power of the Mind. The Mind is also known as the &lsquo;Monkey Mind&rsquo; in
        many cultures, and is the source of all stress and suffering we experience in
        life. The Mind drives our senses towards their attractions, and deludes us from
        taking control of our life.
        <br />
        <br />
        Hence, in Vihangam Yog, one learns how to gain complete control over this Mind.
      </>
    ),
  },
  {
    question: 'What do you need to practice Vihangam Yog?',
    answer: (
      <>
        All you need is a comfortable place to sit, adequate lighting, and ten minutes in
        the morning and evening. Nothing else is required!
      </>
    ),
  },
  {
    question: 'Who is Sadguru?',
    answer: (
      <>
        The Sadguru is the Master of the technique and path of Vihangam Yog. It is He who
        takes a practitioner from the beginning of the path, to its very peak.
        <br />
        <br />
        Only a Sadguru can anoint a Sadguru.
        <br />
        <br />
        Hence, the role and authority of Sadguru is utmost unique. In the modern day, the
        current Sadguru is <b>Sadguru Swatantra Deo Ji Maharaj</b>, who has travelled to
        over 50+ countries, propagating the science of Vihangam Yog.
      </>
    ),
  },
  {
    question: 'What are the benefits of Vihangam Yog?',
    answer: (
      <>
        There are numerous physical, mental, and spiritual benefits when practicing
        Vihangam Yog. Practitioners cite an increase in memory power, sleep quality,
        decision power, and the emergence of a sense of restfulness, mixed with alertness.
        One’s ability to focus and concentrate is boosted, enabling progress in all
        dimensions of life. As a complete spiritual path, for those who seek spiritual
        benefits, Vihangam Yog exemplifies and reveals all.
      </>
    ),
  },
  {
    question: 'What is Swarved?',
    answer: (
      <>
        The Founder of Vihangam Yog, Sadguru Sadafal Deo Ji Maharaj penned over 30+ books
        describing the entirety of His spiritual journey. Amongst these, the most lauded
        of His writings is <b>Swarved</b>, in which He demystifies the most complex
        experiences and concepts of the spiritual path in the simplest of ways, based
        entirely upon the meditative experiences which unfolded before Him on His
        spiritual journey.
        <br />
        <br />
        It is a text which is known to be the <b>Encyclopaedia of Spirituality</b>, and
        hence a must-read for seekers of spirituality.
      </>
    ),
  },
  {
    question: 'How long do you meditate for?',
    answer: (
      <>
        The first stage of Vihangam Yog meditation is practiced twice a day for ten
        minutes.
        <br />
        <br />
        As with everything, there is an ideal time to meditate in the morning and evening
        – the time before sunrise and the time before sunset, respectively. For those who
        follow a different schedule, a common approach is to practice it before and after
        going to sleep.
      </>
    ),
  },
  {
    question: 'How quickly can I expect changes?',
    answer: (
      <>
        The meditative path is unique for everyone. When following the procedure of a
        proven experiment, the results of it become repeatable. Such is the wonders of
        Vihangam Yog: If you practice it as it has been prescribed, the results unfold.
        <br />
        <br />
        Across the globe, millions of practitioners have lauded the benefits of Vihangam
        Yog. We are excited to see you embark upon this journey, with the Sadguru.
      </>
    ),
  },
];
