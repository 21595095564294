import React from 'react';
import { MasterItem, Masters } from '../../constants/Masters';

export const MastersSection = () => {
  return (
    <section
      id="Masters-section"
      className="mt-8 xs:mt-20 w-full m-auto px-6 md:px-36 lg:px-60"
    >
      <h2 className="m-auto text-center font-display font-black text-3xl">
        Meet The Masters
      </h2>
      <div className="mt-6 xs:mt-12 items-center justify-center w-full grid grid-cols-1 sm:grid-cols-2 m-auto">
        {Masters.map((MasterData, index) => (
          <Master {...MasterData} key={index} />
        ))}
      </div>
    </section>
  );
};

const Master: React.FC<MasterItem> = ({ Subtitle, Title, ImageSrc, ImageAlt }) => {
  return (
    <div className="relative">
      <div className="absolute w-full z-0 left-0 bottom-0 h-1/2 from-black/0 to-black bg-gradient-to-b" />
      <img
        loading="lazy"
        src={ImageSrc}
        alt={ImageAlt}
        className="h-[320px] sm:h-[480px] w-full object-[50%_15%] object-cover select-none pointer-events-none"
      />
      <div className="text-white absolute px-2 bottom-9 text-center left-1/2 -translate-x-1/2 w-full flex flex-col items-center justify-center">
        <p className="font-display text-sm sm:text-base md:text-xl font-bold">{Title}</p>
        <p className="font-serif text-sm sm:text-base md:text-lg">{Subtitle}</p>
      </div>
    </div>
  );
};
