import clsx from 'clsx';
import { carousel } from '../../../constants/carousel';
import Sprite from '../../sprites/sprite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const totalImages = carousel.length;

export const Carousel = () => {
  const [activeImage, setActiveImage] = useState(0);
  const { doha, quote } = useMemo(() => carousel[activeImage], [activeImage]);

  const startAnimation = (activeImage: number) => {
    const image = document.getElementById(`carousel-image-${activeImage}`);

    const oldAnimation = animationRef.current;

    animationRef.current = new Animation(
      new KeyframeEffect(
        image,
        [{ transform: 'scale(1)' }, { transform: 'scale(1.1)' }],
        {
          duration: 20_000,
        },
      ),
    );
    animationRef.current.play();

    oldAnimation?.pause();
    oldAnimation?.commitStyles();

    return activeImage;
  };

  const onClickPrev = useCallback(() => {
    setActiveImage((prev) => {
      const index = Math.max(0, prev - 1);
      startAnimation(index);
      return index;
    });
  }, []);
  const onClickNext = useCallback(() => {
    setActiveImage((prev) => {
      const index = (prev + 1) % totalImages;
      startAnimation(index);
      return index;
    });
  }, []);

  const animationRef = useRef<Animation | null>(null);

  useEffect(() => {
    const timer = window.setInterval(() => {
      setActiveImage((prev) => {
        const activeImage = (prev + 1) % totalImages;
        startAnimation(activeImage);

        return activeImage;
      });
    }, 20_000);

    startAnimation(0);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="w-full xs:px-6 md:px-36 lg:px-60 flex xs:rounded-xl">
      <div className="w-full h-96 sm:min-h-[500px] md:min-h-[640px] relative overflow-hidden xs:rounded-xl">
        {carousel.map(({ imageSrc, imageSrcAlt }, index) => (
          <img
            loading="lazy"
            src={imageSrc}
            alt={imageSrcAlt}
            key={`carousel-image-${index}`}
            id={`carousel-image-${index}`}
            className={clsx(
              'absolute left-0 top-0 xs:rounded-xl w-full object-cover h-full -z-20 select-none pointer-events-none',
              {
                'opacity-100': activeImage === index,
                'opacity-0': activeImage !== index,
              },
            )}
          />
        ))}
        <button
          onClick={onClickPrev}
          className="bg-black hover:bg-gray-900 z-20 absolute left-4 md:left-5 top-1/2 -translate-y-1/2 rounded-full w-8 h-8 md:w-10 md:h-10 flex flex-col items-center justify-center"
        >
          <Sprite
            name="prev"
            width="13"
            height="20"
            className="-ml-1 w-3 h-3 md:w-4 md:h-4"
          />
        </button>
        <button
          onClick={onClickNext}
          className="bg-black hover:bg-gray-900 z-20 absolute right-4 md:right-5 top-1/2 -translate-y-1/2 rounded-full w-8 h-8 md:w-10 md:h-10 flex flex-col items-center justify-center"
        >
          <Sprite
            name="prev"
            width="13"
            height="20"
            className="rotate-180 -mr-1 w-3 h-3 md:w-4 md:h-4"
          />
        </button>
        <div className="absolute w-full z-0 left-0 bottom-0 xs:rounded-b-md h-1/2 from-black/0 to-black bg-gradient-to-b" />
        <div className="absolute w-full flex flex-col items-center font-serif text-center bottom-[20px] sm:bottom-[40px] left-1/2 -translate-x-1/2 text-white">
          <p className="italic font-light text-base w-full max-w-[92.5%] sm:text-lg md:max-w-[640px] lg:max-w-[720px] lg:text-xl">
            {quote}
          </p>
          <p className="font-bold uppercase mt-3 lg:mt-4 text-xs sm:text-sm tracking-wider">
            Swarved {doha}
          </p>
        </div>
      </div>
    </div>
  );
};
