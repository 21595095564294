const url = (imageId: string) => {
  return `https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/${imageId}/publicMedium`;
};

export const images = {
  'Masters-1': url('26672bfc-e0db-4255-5e89-36a039939800'),
  'Masters-2': url('4b5ae08a-8fee-4aaa-7819-50edfed0fb00'),
  'Masters-3': url('53153e58-9648-45d0-f391-e941798f5b00'),
  'Masters-4': url('34ed6a3b-3793-4f07-05a1-073c27eb5f00'),
  Sarkar: url('e23b2718-565d-420d-20d0-b62961bad600'),
  'carousel-1': url('649b28c8-f122-42b3-58ef-698f056bf300'),
  image_sarika_ji: url('69d42d89-816d-4405-26a2-2971be056700'),
  image_vikas_ji: url('0f96a535-bf95-4905-fde2-929599192f00'),
  image_garima_ji: url('3345123f-896b-401f-95cf-21705dd55a00'),
  image_carmel_ji: url('3345d39d-98de-4db3-3a70-b18a561bc300'),
  chariot: url('ddf90e0c-fd9b-4fc1-5c51-2fa4e2033600'),
};
