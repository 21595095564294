import React from 'react';
import Sprite from '../../sprites/sprite';
import { nav, goToSelector } from '../../routes/_index';
import { Link } from '@remix-run/react';

interface VYUKHeaderProps {
  showNav?: boolean;
}

export const VYUKHeader: React.FC<VYUKHeaderProps> = ({ showNav = true }) => {
  return (
    <header className="w-full mt-6 pb-6 sm:pb-12 pt-36 sm:pt-0 relative px-6 md:px-36 lg:px-60 flex flex-row items-center justify-between text-black xs:border-b-2 border-red-200 border-dashed">
      {showNav ? (
        <nav className="flex flex-row flex-wrap sm:pr-0 items-center gap-2 font-serif uppercase text-xs tracking-wide sm:tracking-wider w-full justify-center sm:justify-between">
          <ul className="flex flex-row items-center gap-2">
            {nav.map(({ link, title }, index) => (
              <React.Fragment key={title}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <a
                  key={index}
                  className="hover:underline cursor-pointer text-center"
                  onClick={() => goToSelector(link)}
                >
                  {title}
                </a>
                {index !== nav.length - 1 ? <span className="xs:block">•</span> : null}
              </React.Fragment>
            ))}
          </ul>
          <div className="flex">
            <button
              className="bg-blue-100 mr-2 hover:bg-blue-50 transition self-start active:bg-blue-200 px-3.5 py-3 uppercase tracking-wide sm:tracking-wider text-xs font-serif rounded-md ml-auto min-w-[unset]"
              onClick={() => goToSelector('donate-section')}
            >
              Donate
            </button>
            <button
              onClick={() => goToSelector('get-in-touch-section')}
              className="bg-blue-100 hover:bg-blue-50 transition active:bg-blue-200 px-3.5 py-3 uppercase tracking-wide sm:tracking-wider text-xs font-serif rounded-md ml-auto min-w-[unset]"
            >
              Learn to Meditate
            </button>
          </div>
        </nav>
      ) : null}
      <Link to="/" prefetch="intent">
        <div className="w-full sm:w-[unset] placeholder:flex flex-col text-center items-center px-7 -mb-6 bg-white absolute left-1/2 -translate-x-1/2 -top-2">
          <Sprite
            name="logo"
            width="64"
            height="64"
            className="w-12 h-12 md:w-16 md:h-16 m-auto"
          />
          <h1 className="uppercase mt-5 font-semibold font-sans tracking-wider text-base sm:text-lg">
            Vihangam Yog
          </h1>
          <p className="font-serif mt-1 text-base sm:text-lg leading-none">
            Unwinding Spirituality
          </p>
          <p className="font-serif mt-1.5 leading-normal uppercase text-gray-400 text-xxs sm:text-xs tracking-widest">
            United Kingdom
          </p>
        </div>
      </Link>
      {/* {showNav ? (
        <button
          onClick={() => goToSelector('get-in-touch-section')}
          className="bg-blue-100 hover:bg-blue-50 transition active:bg-blue-200 px-3.5 py-3 uppercase tracking-wide sm:tracking-wider text-xs font-serif rounded-md"
        >
          Learn to Meditate
        </button>
      ) : null} */}
    </header>
  );
};
