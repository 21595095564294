import { ChangeEvent, useCallback, useState } from 'react';
import Sprite from '../../sprites/sprite';
import clsx from 'clsx';

import { Key } from 'react-aria-components';
import { Tooltip } from './tooltip';
import { InputError, getError, hasError } from './error';
import { InterestSelector } from './interest-selector';

export const LIMIT = 20;

export const interests = [
  'Learning to meditate',
  'Learning advanced breathing techniques',
  'Learning to relax',
  'Learning to sleep better',
  'Managing stress',
  'Dealing with my anxiety',
  'Controlling my anger',
  'Addressing an addiction I have',
  'Developing more self-esteem',
  'Learning to manage my use of technology & social media',
  'Other',
];

export type GetInTouchProps = {
  onSubmit?: (seeker: Seeker) => void;
  showSuccess?: boolean;
};

export type Seeker = {
  name: string;
  email: string;
  interests: string[];
};

const validateSeeker = (seeker: Seeker): InputError[] => {
  const errors: InputError[] = [];

  if (!seeker.name || seeker.name.trim().length === 0) {
    errors.push({ type: 'name' });
  }

  if (!seeker.email || !seeker.email.includes('@')) {
    errors.push({ type: 'email' });
  }

  if (seeker.interests.length === 0) {
    errors.push({ type: 'interests' });
  }

  return errors;
};

export const GetInTouch = ({ onSubmit, showSuccess }: GetInTouchProps) => {
  const [selected, setSelected] = useState<string[]>([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [errors, setErrors] = useState<InputError[]>([]);

  const onSelect = useCallback((key: Key) => {
    setErrors([]);
    setSelected((curr) => {
      if (curr.includes(key.toString())) {
        return curr.filter((k) => k !== key);
      }
      return [...curr, key.toString()];
    });
  }, []);

  const onReset = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setErrors([]);
    setName('');
    setEmail('');
    setSelected([]);
  }, []);

  const onClear = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setErrors([]);
    setSelected([]);
  }, []);

  const onNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setErrors([]);
    setName(e.target.value);
  }, []);
  const onEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setErrors([]);
    setEmail(e.target.value);
  }, []);

  const onSubmitHandler = useCallback(
    (e: ChangeEvent<HTMLFormElement>) => {
      e.preventDefault();

      const seeker: Seeker = { name, email, interests: selected };
      const errors = validateSeeker(seeker);

      if (errors.length > 0) {
        setErrors(errors);
        return;
      }

      onSubmit?.(seeker);
    },
    [name, email, selected, onSubmit],
  );

  if (showSuccess) {
    return (
      <div className="w-full max-w-[1008px] rounded-xl bg-green-200 px-12 py-16">
        <h2 className="font-display text-3xl font-bold">Thank you.</h2>
        <p className="font-serif mt-4">
          We&apos;ve received your request and will get back to you soon!
        </p>
      </div>
    );
  }

  return (
    <form
      className="w-full max-w-[1008px] rounded-t-xl bg-blue-100 px-12 py-16"
      onSubmit={onSubmitHandler}
    >
      <div>
        <div>
          <div className="flex flex-col sm:flex-row gap-3 sm:items-center">
            <h2 className="font-display text-3xl font-bold">Get in touch with us</h2>
            <Tooltip text="All data is transferred over a secure connection.">
              <Sprite name="safe" width={40} height={40} className="w-8 h-8" />
            </Tooltip>
          </div>
          <p className="font-serif mt-4">
            Interested in learning how to meditate? Reach us below.
          </p>
        </div>
        <div className="px-2 py-3 bg-yellow-50 rounded-lg flex items-start gap-2 mt-8">
          <Sprite name="video" width={20} height={20} className="w-5 h-5" />
          <div>
            <h3 className="font-display font-bold leading-none">
              Join our daily meditation sessions
            </h3>
            <p className="text-xs text-gray-400 uppercase tracking-wide mt-2">
              10PM UK / 1PM EU •{' '}
              <a
                href="https://us02web.zoom.us/j/9365816928"
                target="_blank"
                rel="noreferrer"
                className="text-blue-500"
              >
                Join via Zoom
              </a>{' '}
              (passcode = 12345)
            </p>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <InterestSelector
          selected={selected}
          onSelect={onSelect}
          onClear={onClear}
          hasError={hasError(errors, 'interests')}
        />
        {getError(errors, 'interests')}
      </div>
      <div className="mt-4 flex flex-col sm:flex-row gap-4">
        <span className="flex-1">
          <input
            type="text"
            placeholder="Enter your name..."
            className={clsx(
              'w-full bg-white p-4 rounded-md outline-none border-2',
              hasError(errors, 'name') ? 'border-red-500' : 'border-transparent',
            )}
            onChange={onNameChange}
            value={name}
          />
          {getError(errors, 'name')}
        </span>
        <span className="flex-1">
          <input
            type="email"
            placeholder="Enter your email..."
            className={clsx(
              'w-full bg-white p-4 rounded-md outline-none border-2',
              hasError(errors, 'email') ? 'border-red-500' : 'border-transparent',
            )}
            onChange={onEmailChange}
            value={email}
          />
          {getError(errors, 'email')}
        </span>
      </div>
      <div className="mt-8 flex gap-4 w-full">
        <button
          className="px-5 py-3 uppercase tracking-widest text-xs bg-yellow-100 rounded-md hover:bg-yellow-200 active:bg-yellow-50"
          type="submit"
        >
          Submit
        </button>
        <button
          className="px-5 py-3 uppercase tracking-widest text-xs bg-white hover:bg-gray-100 active:bg-gray-50 rounded-md"
          onClick={onReset}
        >
          Reset
        </button>
      </div>
    </form>
  );
};
