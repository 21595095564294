import Sprite from '../../sprites/sprite';
import clsx from 'clsx';
import {
  Button,
  Key,
  Label,
  ListBox,
  ListBoxItem,
  Popover,
  Select,
} from 'react-aria-components';
import { Tooltip } from './tooltip';
import { LIMIT, interests } from './get-in-touch';

type InterestSelectorProps = {
  selected: string[];
  onSelect: (key: Key) => void;
  onClear: (e: React.MouseEvent<HTMLButtonElement>) => void;
  hasError: boolean;
};

export const InterestSelector = ({
  selected,
  onSelect,
  onClear,
  hasError,
}: InterestSelectorProps) => {
  return (
    <Select
      onSelectionChange={onSelect}
      className={clsx(
        'w-full bg-white p-4 rounded-md outline-none relative border-2',
        hasError ? 'border-red-500' : 'border-transparent',
      )}
    >
      <Label hidden>Favorite Animal</Label>
      <Button className="w-full flex justify-between">
        {selected.length > 0 ? (
          <span className="flex gap-3 flex-wrap">
            {selected.map((s, idx) => {
              const overLimit = s.length > LIMIT;
              const item = (
                <span className="px-3 bg-orange-100 rounded-md" key={idx}>
                  {overLimit ? `${s.slice(0, LIMIT)}...` : s}
                </span>
              );

              if (overLimit) {
                return (
                  <Tooltip key={idx} text={s}>
                    {item}
                  </Tooltip>
                );
              } else {
                return item;
              }
            })}
          </span>
        ) : (
          <span className="text-gray-500">I am interested in...</span>
        )}
        <Sprite name="down" width={20} height={13} className="h-6" />
      </Button>
      {selected.length > 0 ? (
        <button className="absolute -right-2 -top-2" onClick={onClear}>
          <Sprite name="close" width={18} height={18} className="h-6" />
        </button>
      ) : null}
      <Popover className="shadow-lg rounded-md bg-gray-200 outline-none overflow-auto">
        <ListBox selectionBehavior="toggle" className="bg-white w-full outline-none">
          {interests.map((interest) => (
            <ListBoxItem
              key={interest}
              id={interest}
              className={clsx(
                'px-8 py-4 cursor-pointer  transition',
                selected.includes(interest) ? 'bg-orange-100' : 'hover:bg-orange-50',
              )}
            >
              {interest}
            </ListBoxItem>
          ))}
        </ListBox>
      </Popover>
    </Select>
  );
};
