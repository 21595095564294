import { images } from '../../constants/images';
import Sprite from '../sprites/sprite';

export const SarkarSection = () => {
  return (
    <section className="relative mt-20 w-full flex flex-col sm:flex-row justify-center items-center">
      <img
        loading="lazy"
        src={images['Sarkar']}
        alt="Sant Naam Deo Ji Maharaj"
        className="object-cover object-[50%_60%] h-[480px] sm:h-[560px] md:h-[640px] w-full sm:max-w-[50%] select-none pointer-events-none"
      />
      <div className="flex flex-col w-full px-6 xs:px-9 sm:pt-20 m-auto mt-8 sm:mt-0 sm:m-[unset] sm:w-[50%] md:px-16 items-center sm:items-start">
        <Sprite name="quote" width="28" height="21" className="w-7 h-5" />
        <h2 className="font-display font-bold text-center sm:text-left text-2xl w-[90%] md:w-[500px] md:text-3xl lg:text-4xl mt-6 leading-tight">
          The silence within us opens up the door to the universe to begin conversing with
          us.
        </h2>
        <p className="font-bold italic font-display mt-7 sm:text-lg text-center sm:text-left">
          Sant Naam Deo Ji Maharaj
        </p>
        <p className="font-serif text-sm text-center sm:text-left sm:text-base">
          International Convenor of Vihangam Yog
        </p>
        <div className="border-b h-[1px] border-dashed border-gray-500 mt-9 mb-8 w-[80%] sm:w-full sm:max-w-[440px] md:max-w-[520px] border-g" />
        <div className="w-full md:max-w-[520px] px-0.5 py-0.5 rounded-xl flex flex-col items-center justify-center from-[#DBF4B2] to-[#C7E696] bg-gradient-to-r">
          <div className="w-full px-4 sm:px-12 rounded-xl py-7 flex flex-col items-center from-[#E6FFBE] to-[#F1FFDB] bg-gradient-to-r">
            <span className="flex flex-col sm:flex-row gap-2 sm:gap-0 items-center">
              <Sprite name="audio" width="34" height="26" className="h-5 md:w-8 md:h-7" />
              <span className="sm:ml-2 sm:text-nowrap sm:whitespace-nowrap italic font-display px-1 xs:px-4 sm:px-0 font-bold leading-tight text-center">
                Inner Wellbeing - with Naam Deo Ji
              </span>
            </span>
            <p className="font-serif text-center leading-tight mt-3">
              Tune in at 5PM GMT on{' '}
              <a
                href="https://rangoliradio.com/listen-live"
                target="_blank"
                rel="noreferrer"
                className="text-blue-500 underline"
              >
                Rangoli Radio
              </a>{' '}
              <br className="hidden sm:block" />
              Saturday, the 25th of May, 2024
            </p>
            <a
              href="https://pub-24db26cc83d24526bdd25020f72d9ed8.r2.dev/Rangoli%20Radio%2025%20May%202024.pdf"
              download
              className="mt-6"
              target="_blank"
              rel="noreferrer"
            >
              <span className="cursor-pointer hover:underline text-gray-500 font-light text-xs font-serif uppercase tracking-wider">
                Learn more →
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="h-5/6 bg-gray-100 w-full top-1/2 -translate-y-1/2 left-0 absolute -z-10" />
    </section>
  );
};
