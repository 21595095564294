import { videoCards } from '../../constants/video-cards';
import { VideoCard } from '../components/video-card/video-card';

export const BenefitsSection = () => {
  return (
    <section
      id="benefits-section"
      className="mt-12 sm:mt-15 px-6 md:px-36 lg:px-60 items-center justify-center gap-6 md:gap-8 w-full grid grid-cols-1 sm:grid-cols-2 m-auto"
    >
      {videoCards.map((videoCard, index) => (
        <VideoCard {...videoCard} key={index} />
      ))}
    </section>
  );
};
