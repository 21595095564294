export interface VideoCardItem {
  videoSrc: string;
  title: string;
  description: React.ReactNode;
  color: 'blue' | 'yellow' | 'red' | 'green';
}

export const videoCards: VideoCardItem[] = [
  {
    videoSrc: '',
    title: 'Calm yourself',
    description:
      'Discover a state of peace independent from the busy world we live in. Inner peace awaits.',
    color: 'blue',
  },
  {
    // disclaimer
    // youth and workplace

    // links for past radio sessions?

    // should we have a donation link in the UK?

    // publications from news articles, history in UK => past events

    // move the event action further to the top

    // change the photo of current Swamiji

    // basic analytics, visitor count at the bottom

    videoSrc: '',
    title: 'Accept the challenge',
    description:
      'The most impactful individuals overcome the most difficult of challenges. Overcome and prosper.',
    color: 'yellow',
  },
  {
    videoSrc: '',
    title: 'Alert but restful',
    description: (
      <>
        Experience <span className="italic">&ldquo;Restful alertness&rdquo;</span> - a
        state where one is at peace, but fully attentive.
      </>
    ),
    color: 'red',
  },
  {
    videoSrc: '',
    title: 'Become resilient',
    description:
      'Irrespective of what goes on around you, you will be the same; strong and at peace.',
    color: 'green',
  },
];
