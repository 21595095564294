export type InputError = {
  type: 'name' | 'interests' | 'email';
};

export const hasError = (errors: InputError[], type: InputError['type']) => {
  return errors.some((e) => e.type === type);
};

export const getError = (errors: InputError[], type: InputError['type']) => {
  const error = errors.find((e) => e.type === type);
  if (!error) {
    return undefined;
  }

  return <ErrorText type={error.type} />;
};

const ErrorText = ({ type }: { type: InputError['type'] }) => {
  switch (type) {
    case 'name':
      return <p className="text-red-500 text-sm px-4 pt-2">Please enter your name</p>;
    case 'email':
      return <p className="text-red-500 text-sm px-4 pt-2">Please enter a valid email</p>;
    case 'interests':
      return (
        <p className="text-red-500 text-sm px-4 pt-2">
          Please select at least one interest
        </p>
      );
  }

  return undefined;
};
