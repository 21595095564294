import { images } from './images';

export interface MasterItem {
  Title: string;
  Subtitle: string;
  ImageSrc: string;
  ImageAlt: string;
}

export const Masters: MasterItem[] = [
  {
    ImageSrc: images['Masters-1'],
    ImageAlt: 'Sadguru Sadafal Deo Ji Maharaj',
    Title: 'Sadguru Sadafal Deo Ji Maharaj',
    Subtitle: 'The Founder of Vihangam Yog Sansthan',
  },
  {
    ImageSrc: images['Masters-2'],
    ImageAlt: 'Sadguru Dharmachandra Deo Ji Maharaj',
    Title: 'Sadguru Dharmachandra Deo Ji Maharaj',
    Subtitle: 'The First Succeeding Sadguru',
  },
  {
    ImageSrc: images['Masters-3'],
    ImageAlt: 'Sadguru Swatantra Deo Ji Maharaj',
    Title: 'Sadguru Swatantra Deo Ji Maharaj',
    Subtitle: 'The Current Master',
  },
  {
    ImageSrc: images['Masters-4'],
    ImageAlt: 'Sant Pravar Vigyaan Deo Ji Maharaj',
    Title: 'Sant Pravar Vigyaan Deo Ji Maharaj',
    Subtitle: 'Sadguru Uttarādhikhārī',
  },
];
