import { testimonials } from '../../constants/testimonials';
import { Testimonial } from '../components/testimonial/testimonial';

export const TestimonialSection = () => {
  return (
    <section className="mt-12 w-full m-auto px-6 md:px-36 lg:px-60">
      <div className="sm:mt-15 items-start justify-center gap-8 md:gap-16 w-fit grid grid-cols-1 sm:grid-cols-2 m-auto">
        {testimonials.map((testimonial, index) => (
          <Testimonial {...testimonial} key={index} />
        ))}
      </div>
      <div className="h-[1px] border-b border-dashed border-gray-500 max-w-[640px] m-auto mt-12 mb-10 md:mt-16 md:mb-12" />
      <p className="font-serif text-center m-auto max-w-[640px]">
        Rediscovered by His Holiness, Sadguru Sadafal Deo Ji Maharaj in 1924, Vihangam Yog
        has transformed millions around the globe through a simple, scientifically-backed
        technique.
      </p>
    </section>
  );
};
