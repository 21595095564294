import React, { useState } from 'react';

interface TooltipProps {
  text: string | null;
  children: React.ReactNode;
}

export const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  if (!text) return <>{children}</>;

  return (
    <div
      className="relative"
      onFocus={() => {}}
      onMouseOver={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip ? (
        <span
          className={`rounded-md absolute left-1/2 top-[-40px] transform -translate-x-1/2 animate-fadeIn select-none pointer-events-none whitespace-nowrap bg-black text-white px-3 py-1.5 flex flex-col items-center justify-center after:absolute after:top-full after:h-0 after:w-0 after:border-x-4 after:border-x-transparent after:border-t-[4px] after:border-t-black`}
        >
          {text}
        </span>
      ) : null}
    </div>
  );
};
